// Import HMR API types from Vite
// Store DOM elements
const elements = {
	productForm: document.getElementById('productForm'),
	productsList: document.getElementById('productsList'),
	loadingSpinner: document.getElementById('loading'),
};

let editingProductId = null;

const showLoading = () => {
	elements.loadingSpinner.style.display = 'block';
};

const hideLoading = () => {
	elements.loadingSpinner.style.display = 'none';
};

// Separate the product display logic
const createProductCard = (product) => `
	<div class="product-card" data-id="${product.id}">
	  ${
			product.imageUrl
				? `<img src="${product.imageUrl}" alt="${product.name}" class="product-image">`
				: ''
		}
	  <h3>${product.name}</h3>
	  <p>${product.description}</p>
	  <p class="price">$${product.price}</p>
	  <div class="actions">
		<button onclick="editProduct('${product.id}')" class="edit">Edit</button>
		<button onclick="deleteProduct('${product.id}')" class="delete">Delete</button>
	  </div>
	</div>
  `;

const displayProducts = async () => {
	try {
		showLoading();
		const response = await fetch('/api/products');
		const products = await response.json();
		elements.productsList.innerHTML = products.map(createProductCard).join('');
	} catch (error) {
		console.error('Error loading products:', error);
		console.error('Failed to load products');
	} finally {
		hideLoading();
	}
};

const handleSubmit = async (event) => {
	event.preventDefault();
	showLoading();

	try {
		const formData = new FormData(elements.productForm);
		const imageFile = formData.get('image');

		// Validate image if present
		if (imageFile.size > 0) {
			if (imageFile.size > 2 * 1024 * 1024) {
				alert('Image size must be less than 2MB');
				return;
			}

			const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
			if (!validTypes.includes(imageFile.type)) {
				alert('Invalid image type. Only JPG, PNG, and SVG are allowed.');
				return;
			}
		}

		const url = editingProductId
			? `/api/products/${editingProductId}`
			: '/api/products';

		const method = editingProductId ? 'PUT' : 'POST';

		const response = await fetch(url, {
			method,
			body: formData,
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		elements.productForm.reset();
		editingProductId = null;
		document.getElementById('submitButton').textContent = 'Add Product';
		await displayProducts();
	} catch (error) {
		console.error('Error saving product:', error);
		alert('Failed to save product');
	} finally {
		hideLoading();
	}
};

const editProduct = async (productId) => {
	try {
		showLoading();
		const response = await fetch(`/api/products/${productId}`);
		const product = await response.json();

		elements.productForm.name.value = product.name;
		elements.productForm.description.value = product.description;
		elements.productForm.price.value = product.price;

		editingProductId = productId;
		document.getElementById('submitButton').textContent = 'Update Product';
		elements.productForm.scrollIntoView({ behavior: 'smooth' });
	} catch (error) {
		console.error('Error loading product for edit:', error);
		console.error('Failed to load product for editing...');
	} finally {
		hideLoading();
	}
};

const deleteProduct = async (productId) => {
	if (!confirm('Are you sure you want to delete this product?')) {
		return;
	}

	try {
		showLoading();
		const response = await fetch(`/api/products/${productId}`, {
			method: 'DELETE',
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		await displayProducts();
	} catch (error) {
		console.error('Error deleting product:', error);
		alert('Failed to delete product');
	} finally {
		hideLoading();
	}
};

// Initialize the app
const initializeApp = () => {
	elements.productForm.addEventListener('submit', handleSubmit);
	displayProducts();

	// Make functions available globally for event handlers
	window.editProduct = editProduct;
	window.deleteProduct = deleteProduct;
};

initializeApp();

// HMR handling
if (import.meta.hot) {
	import.meta.hot.accept((newModule) => {
		console.log('🔄 HMR Update Applied');
	});

	// Preserve the form data during HMR updates
	import.meta.hot.dispose(() => {
		const formData = new FormData(elements.productForm);
		import.meta.hot.data.formState = Object.fromEntries(formData.entries());
		import.meta.hot.data.editingId = editingProductId;
	});

	// Restore form state after HMR update
	if (import.meta.hot.data.formState) {
		const { formState, editingId } = import.meta.hot.data;
		Object.entries(formState).forEach(([key, value]) => {
			const input = elements.productForm.elements[key];
			if (input) input.value = value;
		});
		editingProductId = editingId;
	}
}

// Export for HMR
export { displayProducts, handleSubmit, editProduct, deleteProduct };
